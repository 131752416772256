<template>
  <div class="container-fluid py-4 add-student">
 <div class="row">

   <div class="col-12">
     <div class="card my-4">
       <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
         <div
           class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
         >
           <h6 class="text-white text-capitalize ps-3">  تعديل  الدفعية</h6>
         </div>
       </div>
       <div class="card-body px-0 pb-2">
                <form @submit="updatePayment" role="form" class="text-start mt-3">
                    <div class="row">
             <div class="mb-3 col-lg-6">
                 <label>  العملاء <span class="red">*</span>
                 </label>
                 <multiselect
                     class='required form-control'
                     id='grade'
                     v-model=" selectedClient"
                     :options='clients'
                     :searchable='true'
                     :close-on-select='true'
                     placeholder=" اختر العميل"
                     @select='getEvent($event)'
                     label='name'
                     :custom-label="customLabel"
                     track-by='id'
                     required='required'
                     :internal-search="true"
                     >
                   >
                     <template v-slot:noOptions>
                       <span> فارغة </span>
                     </template>
                     <template v-slot:noResult>
                       <span>لا يوجد نتيجة </span>
                     </template>
                   </multiselect>

             </div>
             <div class="mb-3 col-lg-6">
                 <label>  الحفلة <span class="red">*</span>
                 </label>
                 <multiselect
                     class='required form-control'
                     id='grade'
                     v-model=" selectedEvent"
                     :options='events'
                     :searchable='true'
                     :close-on-select='true'
                     placeholder=" اختر الحفلة"
                     label='name'
                     :custom-label="customLabel"
                     track-by='id'
                     required='required'
                     :internal-search="true"
                     >
                   >
                     <template v-slot:noOptions>
                       <span> فارغة </span>
                     </template>
                     <template v-slot:noResult>
                       <span>لا يوجد نتيجة </span>
                     </template>
                   </multiselect>

             </div>
             <div class="mb-3 col-lg-6">
                    <label> سعر الحفلة  (للعميل )
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='eventPrices.clientPrice'
                   disabled

                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> سعر الحفلة  (للمرافق )
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='eventPrices.compPrice'
                   disabled

                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> عدد المرافقين
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='compCount'
                   disabled

                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> الإجمالى المطلوب
                    </label>
                  <vmd-input
                    id=""
                    type="number"
                    v-model="eventPrices.total"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                 <label>  المسدد
                 </label>
               <vmd-input
                 id=""
                 type="text"
                v-model='payment.amount'
                disabled

               />
             </div>
             <div class="mb-3 col-lg-6">
                 <label>  المتبقى
                 </label>
               <vmd-input
                 id=""
                 type="text"
                v-model='payment.remaining_amount'
                disabled

               />
             </div>
             <div class="mb-3 col-lg-6">
                 <label>المبلغ المدفوع<span class="red">*</span>
                 </label>
               <vmd-input
                 id=""
                 type="text"
                label="  ادخل القيمة"
                v-model="payment.newAmount"
                required

               />
             </div>
             </div>
                    <div class="row">
             <div class="text-center col-lg-6">
               <vmd-button
                 class="my-4 mb-2"
                 variant="gradient"
                 color="info"
                 > حفظ</vmd-button
               >
             </div>
              <div class="text-center col-lg-6">
                <router-link to="/clients-payments">
               <vmd-button
               type='button'
                 class="my-4 mb-2"
                 variant="gradient"
                 color="warning"
                 > رجوع</vmd-button
               >
              </router-link>
             </div>
                    </div>
           </form>
     </div>
   </div>
   </div>
 </div>
 </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      payment: {},
      events: [],
      selectedEvent: '',
      clients: [],
      selectedClient: '',
      compCount: '',
      eventPrices: {},
      errors: {}
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}client-payments/${this.$route.params.id}`)
      .then((response) => {
        this.payment = response.data.data
        this.compCount = this.payment.client.companions_count || 1

        const res = response.data.data
        if (res.client && res.client.id) {
          this.selectedClient = {
            id: res.client.id,
            name: res.client.name
          }

          this.getEvent(this.selectedClient)
        } else {
          console.error('Client data is missing in the response.')
        }
      })
      .catch((error) => {
        console.error('Error fetching client payment details:', error)
      })

    this.$http.get(`${this.$hostUrl}clients`)
      .then((response) => {
        this.clients = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
    this.$http.get(`${this.$hostUrl}events`)
      .then((response) => {
        this.events = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
  },

  methods: {
    getEvent ($event) {
      this.$http.get(`${this.$hostUrl}clients/${$event.id}`)
        .then((response) => {
          const clientEvent = response.data.data.event
          const compCount = response.data.data.companions_count || 1
          this.selectedEvent = {
            id: clientEvent.id,
            name: clientEvent.name
          }
          this.eventPrices = {
            clientPrice: clientEvent?.client_price,
            compPrice: clientEvent?.companion_price,
            total: parseInt(clientEvent.client_price) + parseInt(compCount * clientEvent.companion_price)
          }
        })
    },
    async updatePayment (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('amount', this.payment.newAmount)
      formData.append('client_id', this.selectedClient.id)
      formData.append('event_id', this.selectedEvent.id)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'client-payments/' + this.$route.params.id, '/clients-payments')
    }
  }
}
</script>
<style >
.error{
color:#f44335 !important;
font-size:13px;
padding-right: 25px;
}
.err-msg{
 position: absolute;
 padding-top: 0px;
 margin-top: -10px;
 font-size:14px;
}
.unrequired{
 display: flex;
 gap: 5px;
}
.li-h{
line-height:87px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
 padding: 20px !important;
 margin-top: 26px !important;
 margin-left: unset !important
}

</style>
