<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">

      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  اضافة دفعية</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="createPayment" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>  المرافقين <span class="red">*</span>
                    </label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedComp"
                        :options='companions'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المرافق"
                        @select='getEvent($event)'
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>

                </div>
                <div class="mb-3 col-lg-6">
                    <label>  الحفلة <span class="red">*</span>
                    </label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedEvent"
                        :options='events'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الحفلة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>

                </div>
                <div class="mb-3 col-lg-6">
                    <label> سعر الحفلة
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='payment.eventPrice'
                   disabled

                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>القيمة<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label="  ادخل القيمة"
                   v-model="payment.amount"
                   required

                  />
                </div>
                </div>
                <div class="row" v-if="compPayments?.length" >
                  <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase font-weight-bolder"> الحفلة</th>
                                         <th class="text-uppercase font-weight-bolder"> المبلغ المدفوع</th>
                      <th class="text-uppercase font-weight-bolder">
                           المبلغ المتبقي
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                        تاريخ الدفع
                      </th>
                    </tr>
                  </thead>
                 <tbody>
                    <tr v-for="item in compPayments" :key="item.id">
                      <td>
                        <p class="text-s mb-0">{{ item.event_id }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ item.amount}}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ item.remaining_amount }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ item.created_at }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
                </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      payment: {},
      events: [],
      selectedEvent: '',
      companions: [],
      selectedComp: '',
      compPayments: [],
      errors: {}
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}companions`)
      .then((response) => {
        this.companions = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
    this.$http.get(`${this.$hostUrl}events`)
      .then((response) => {
        this.events = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
  },

  methods: {
    getEvent ($event) {
      this.$http.get(`${this.$hostUrl}companions/${$event.id}`)
        .then((response) => {
          this.compPayments = response?.data?.data?.payments || []
          const clientEvent = response.data.data.event
          this.selectedEvent = {
            id: clientEvent.id,
            name: clientEvent.name
          }
          this.payment.eventPrice = clientEvent.companion_price
        })
    },
    async createPayment (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('amount', this.payment.amount)
      formData.append('companion_id', this.selectedComp.id)
      formData.append('event_id', this.selectedEvent.id)
      this.errors = await this.postRequest(formData, this.$hostUrl + 'companion-payments', '/companions-payments')
    }
  }
}
</script>
<style >
.error{
  color:#f44335 !important;
  font-size:13px;
   padding-right: 25px;
}
.err-msg{
    position: absolute;
    padding-top: 0px;
    margin-top: -10px;
    font-size:14px;
}
.unrequired{
    display: flex;
    gap: 5px;
}
.li-h{
  line-height:87px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
 rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    padding: 20px !important;
    margin-top: 26px !important;
    margin-left: unset !important
}

</style>
