<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  اضافة عميل</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="createClient" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label>اسم العميل<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label="الاسم  بالعربي"
                   v-model='client.name'
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> الاسم بالإنجليزية
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label="الاسم  بالإنجليزية"
                   v-model='client.nameEn'
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  رقم الهاتف <span class="red">*</span>
                      <br/>
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   label=" ادخل رقم الهاتف"
                   v-model='client.phone'
                    required
                  />
                  <label class='err-msg red' style="position:relative" v-if="errors.phone">{{errors?.phone[0]}}</label>
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  الحفلة <span class="red">*</span>
                    </label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model=" selectedEvent"
                        :options='events'
                        @select='getEventPrices($event)'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الحفلة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>

                </div>
                <div class="mb-3 col-lg-6">
                    <label> عدد المرافقين
                    </label>
                  <vmd-input
                    id=""
                    type="number"
                   label="عدد المرافقين"
                   v-model='client.companionsNum'
                    @input="calcTotal"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  سعر الحفلة للعميل
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model="eventPrices.clientPrice"
                   disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  سعر الحفلة للمرافق
                    </label>
                  <vmd-input
                    id=""
                    type="text"
                    v-model="eventPrices.compPrice"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label> الإجمالى المطلوب
                    </label>
                  <vmd-input
                    id=""
                    type="number"
                    v-model="eventPrices.total"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  المبلغ المدفوع<span class="red">*</span>
                    </label>
                  <vmd-input
                    id=""
                    type="number"
                   v-model='client.amount'
                   @input="calcRemainPrice"

                  />
                </div>
                <div class="mb-3 col-lg-6">
                    <label>  المتبقى
                    </label>
                  <vmd-input
                    id=""
                    type="number"
                   v-model='client.remainP'
                   disabled

                  />
                </div>
                </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    > مسح</vmd-button
                  >
                </div>
                       </div>
              </form>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      client: {},
      events: [],
      selectedEvent: '',
      eventPrices: {},
      errors: {}
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}events`)
      .then((response) => {
        this.events = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
  },

  methods: {
    getEventPrices ($event) {
      this.$http.get(`${this.$hostUrl}events/${$event.id}`)
        .then((response) => {
          const event = response.data.data
          this.eventPrices = {
            clientPrice: event.client_price,
            compPrice: event.companion_price,
            total: parseInt(event.client_price) + parseInt(event.companion_price)
          }
        })
    },
    calcTotal () {
      const companionsNum = parseInt(this.client.companionsNum) || 1
      const totalCompPrice = companionsNum * parseInt(this.eventPrices.compPrice)
      this.eventPrices.total = parseInt(this.eventPrices.clientPrice) + totalCompPrice
      this.calcRemainPrice()
    },
    calcRemainPrice () {
      this.client.remainP = this.eventPrices.total - parseInt(this.client.amount)
    },

    async createClient (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.client.name)) {
        swal('اسم الحفلة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.client.name)
      if (this.client.nameEn) {
        formData.append('name_en', this.client.nameEn)
      }
      const globalPhone = '+2' + this.client.phone
      formData.append('phone', globalPhone)
      formData.append('event_id', this.selectedEvent.id)
      formData.append('amount', this.client.amount)
      formData.append('companions_count', this.client.companionsNum)
      this.errors = await this.postRequest(formData, this.$hostUrl + 'clients', '/clients')
    }
  }
}
</script>
<style >
.error{
  color:#f44335 !important;
  font-size:13px;
   padding-right: 25px;
}
.err-msg{
    position: absolute;
    padding-top: 0px;
    margin-top: -10px;
    font-size:14px;
}
.unrequired{
    display: flex;
    gap: 5px;
}
.li-h{
  line-height:87px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
 rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    padding: 20px !important;
    margin-top: 26px !important;
    margin-left: unset !important
}

</style>
