<template>
  <div class="container-fluid py-4 add-student">
 <div class="row">

   <div class="col-12">
     <div class="card my-4">
       <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
         <div
           class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
         >
           <h6 class="text-white text-capitalize ps-3">  تعديل الدفعية</h6>
         </div>
       </div>
       <div class="card-body px-0 pb-2">
                <form @submit="updatePayment" role="form" class="text-start mt-3">
                    <div class="row">
             <div class="mb-3 col-lg-6">
                 <label>  المرافقين <span class="red">*</span>
                 </label>
                 <multiselect
                     class='required form-control'
                     id='grade'
                     v-model=" selectedComp"
                     :options='companions'
                     :searchable='true'
                     :close-on-select='true'
                     placeholder=" اختر المرافق"
                     @select='getEvent($event)'
                     label='name'
                     :custom-label="customLabel"
                     track-by='id'
                     required='required'
                     :internal-search="true"
                     >
                   >
                     <template v-slot:noOptions>
                       <span> فارغة </span>
                     </template>
                     <template v-slot:noResult>
                       <span>لا يوجد نتيجة </span>
                     </template>
                   </multiselect>

             </div>
             <div class="mb-3 col-lg-6">
                 <label>  الحفلة <span class="red">*</span>
                 </label>
                 <multiselect
                     class='required form-control'
                     id='grade'
                     v-model=" selectedEvent"
                     :options='events'
                     :searchable='true'
                     :close-on-select='true'
                     placeholder=" اختر الحفلة"
                     label='name'
                     :custom-label="customLabel"
                     track-by='id'
                     required='required'
                     :internal-search="true"
                     >
                   >
                     <template v-slot:noOptions>
                       <span> فارغة </span>
                     </template>
                     <template v-slot:noResult>
                       <span>لا يوجد نتيجة </span>
                     </template>
                   </multiselect>

             </div>
             <div class="mb-3 col-lg-6">
                 <label> سعر الحفلة
                 </label>
               <vmd-input
                 id=""
                 type="text"
                v-model='compPrice'
                disabled

               />
             </div>
             <div class="mb-3 col-lg-6">
                 <label>  المسدد
                 </label>
               <vmd-input
                 id=""
                 type="text"
                v-model='payment.amount'
                disabled

               />
             </div>
             <div class="mb-3 col-lg-6">
                 <label> المتبقى
                 </label>
               <vmd-input
                 id=""
                 type="text"
                v-model='payment.remaining_amount'
                disabled

               />
             </div>
             <div class="mb-3 col-lg-6">
                 <label>القيمة<span class="red">*</span>
                 </label>
               <vmd-input
                 id=""
                 type="text"
                label="  ادخل القيمة"
                v-model="payment.newAmount"
                required

               />
             </div>
             </div>
                    <div class="row">
             <div class="text-center col-lg-6">
               <vmd-button
                 class="my-4 mb-2"
                 variant="gradient"
                 color="info"
                 > حفظ</vmd-button
               >
             </div>
              <div class="text-center col-lg-6">
                <router-link to="/comapnions-payments">
               <vmd-button
               type='reset'
                 class="my-4 mb-2"
                 variant="gradient"
                 color="warning"
                 > رجوع</vmd-button
               >
              </router-link>
             </div>
                    </div>
           </form>
     </div>
   </div>
   </div>
 </div>
 </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      payment: {},
      events: [],
      selectedEvent: '',
      companions: [],
      selectedComp: '',
      compPrice: '',
      errors: {}
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}companion-payments/${this.$route.params.id}`)
      .then((response) => {
        this.payment = response.data.data
        this.compPrice = this.payment.event.companion_price
        this.selectedComp = {
          id: this.payment?.companion?.id,
          name: this.payment?.companion?.name
        }
        this.$http.get(`${this.$hostUrl}events/${this.payment.event_id}`)
          .then((response) => {
            this.selectedEvent = {
              name: response.data.data.name,
              id: response.data.data.id
            }
          })
      })
    this.$http.get(`${this.$hostUrl}companions`)
      .then((response) => {
        this.companions = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
    this.$http.get(`${this.$hostUrl}events`)
      .then((response) => {
        this.events = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
  },

  methods: {
    getEvent ($event) {
      this.$http.get(`${this.$hostUrl}companions/${$event.id}`)
        .then((response) => {
          const clientEvent = response.data.data.event
          this.selectedEvent = {
            id: clientEvent.id,
            name: clientEvent.name
          }
          this.compPrice = clientEvent.companion_price
        })
    },
    async updatePayment (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('amount', this.payment.newAmount)
      formData.append('companion_id', this.selectedComp.id)
      formData.append('event_id', this.selectedEvent.id)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'companion-payments/' + this.$route.params.id, '/companions-payments')
    }
  }
}
</script>
<style >
.error{
color:#f44335 !important;
font-size:13px;
padding-right: 25px;
}
.err-msg{
 position: absolute;
 padding-top: 0px;
 margin-top: -10px;
 font-size:14px;
}
.unrequired{
 display: flex;
 gap: 5px;
}
.li-h{
line-height:87px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
 padding: 20px !important;
 margin-top: 26px !important;
 margin-left: unset !important
}

</style>
